import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { Notifications } from 'Notifications-Types';

import { NotificationState } from './reducer';

export const getItems = (state: RootState) => state.notifications.items;
export const getErrors = (state: RootState) => state.notifications.errors;

export const getNotifications = (state: RootState) => state.notifications;

export const getReqType = (state: RootState) => state.notifications.reqType;

export const getIsFetching = createSelector(
  getNotifications,
  (state: NotificationState) => state.isFetching,
);

export const getIsFetchingMore = createSelector(
  getNotifications,
  (state: NotificationState) => state.isFetchingMore,
);

export const getUnReadCount = createSelector(getNotifications, (state: NotificationState) =>
  state.status?.reduce((prev, curr) => {
    return prev + curr.unread;
  }, 0),
);

export const getStatus = createSelector(
  getNotifications,
  (state: NotificationState) => state.status,
);

export const getItemById = (notificationId: string) =>
  createSelector(getItems, (state: Notifications) =>
    state.notifications?.find(n => n.notificationId === notificationId),
  );
