import React from 'react';

import { IconProps } from '@travel/icons';
import {
  Australia,
  Canada,
  Hongkong,
  Indonesia,
  Japan,
  Malaysia,
  NewZealand,
  Philippines,
  Singapore,
  SouthKorea,
  Taiwan,
  Thailand,
  UnitedKingdom,
  Usa,
  Vietnam,
} from '@travel/icons/flag';

// we created a separated list for market only
// to reduce importing all of flags in the bundle
const MARKET_FLAGS: {
  [key: string]: React.ComponentType<IconProps>;
} = {
  JPN: Japan,
  MYS: Malaysia,
  TWN: Taiwan,
  KOR: SouthKorea,
  HKG: Hongkong,
  SGP: Singapore,
  AUS: Australia,
  USA: Usa,
  GBR: UnitedKingdom,
  PHL: Philippines,
  IDN: Indonesia,
  THA: Thailand,
  CAN: Canada,
  NZL: NewZealand,
  VNM: Vietnam,
};

export default MARKET_FLAGS;
