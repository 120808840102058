import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { getLocation } from 'store/__router/selectors';

import { baseUrl } from 'core/universalRouter/paths';

export const MOBILE_HEADER_HEIGHT = 59;

function useCustomFixedStyling() {
  const isMobile = useDeviceType() === MOBILE_SCREEN;
  const location = useSelector(getLocation);
  const isCustomFixedStyle = location.pathname.includes(baseUrl.providerList) && isMobile;

  const [isCustomFixedBarHidden, setIsCustomFixedBarHidden] = useState(false);

  const handleBarAppearance = useCallback(() => {
    if (window.pageYOffset >= window.innerHeight + MOBILE_HEADER_HEIGHT) {
      setIsCustomFixedBarHidden(true);
    } else {
      setIsCustomFixedBarHidden(false);
    }
  }, []);

  useEffect(() => {
    if (isCustomFixedStyle) {
      document.addEventListener('scroll', handleBarAppearance);
    }
    return () => {
      document.removeEventListener('scroll', handleBarAppearance);
    };
  }, [isMobile, handleBarAppearance, isCustomFixedStyle]);

  return { isCustomFixedStyle, isCustomFixedBarHidden };
}

export default useCustomFixedStyling;
