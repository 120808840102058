import loadable from '@loadable/component';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useApiClient } from '@travel/api-client';
import {
  getCurrency,
  getLanguage,
  getMarket,
  getSupportedLanguages,
  L10nName,
  L10nNumber,
  setCurrency,
} from '@travel/i18n';
import { Close, Currency, MapOutline, Menu, RakutenPoints, WorldOutline } from '@travel/icons/ui';
import BetaButton from '@travel/traveler-core/components/BetaButton';
import SelectPopup from '@travel/traveler-core/components/SelectPopup';
import { warnOnMarketChangeTo } from '@travel/traveler-core/constants';
import MARKET_FLAGS from '@travel/traveler-core/constants/marketFlags';
import {
  MOBILE_SCREEN,
  useDeviceType,
  useGetTranslation,
  useTranslation,
} from '@travel/traveler-core/hooks';
import { Option } from '@travel/traveler-core/types/header';
import { sessionGetItem, sortOptionsByText } from '@travel/traveler-core/utils';
import { IconText, TravelLogo } from '@travel/ui';
import { cx, isNotEmptyArray } from '@travel/utils';
import { setStrictCookie } from '@travel/utils/src/cookies';

import SmartBanner from 'components/SmartBanner';
import WideBanner from 'components/WideBanner';

import { getLocation } from 'store/__router/selectors';
import { logout } from 'store/authCode/actions';
import { getHeight } from 'store/banner/selectors';
import { getIsMaintenanceMode } from 'store/commonError/selectors';
import { getCurrencyListOptions, getMarkets, getPreferredMarket } from 'store/markets/selectors';
import { fetchMember } from 'store/member/actions';
import { updateLanguageAndCurrency } from 'store/member/apis';
import { getMember } from 'store/member/selectors';
import { getUnReadCount } from 'store/notifications/selectors';
import { getIsSafari } from 'store/userAgent/selectors';

import {
  LAST_USED_CURRENCY_KEY,
  LAST_USED_LANGUAGE_KEY,
  LAST_USED_MARKET_KEY,
} from 'constants/cookies';
import { Translate } from 'core/translate';
import { Link } from 'core/universalRouter/Link';
import paths, { baseUrl, getURLPagePathName } from 'core/universalRouter/paths';
import useDialogHandler from 'hooks/useDialogHandler';
import { MarketItem } from 'Markets-Types';

import useCustomFixedStyling from './useCustomFixedStyling';
import useTransparentStyling from './useTransparentStyling';

import styles from './header.module.scss';

const TABBABLE_ELEMENTS = `a:not([disabled]), input:not([disabled]), button:not([disabled]), textarea:not([disabled]), select:not([disabled]), details:not([disabled]), [tabindex]:not([tabindex="-1"])`;

const ConfirmDialog = loadable(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackFetchPriority: "low" */
      /* webpackChunkName: "component-ConfirmDialog" */
      '@travel/traveler-core/components/ConfirmDialog'
    ),
  { ssr: false },
);
const HeaderDialog = loadable(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackFetchPriority: "low" */
      /* webpackChunkName: "component-HeaderDialog" */
      '@travel/traveler-core/components/HeaderDialog'
    ),
  { ssr: false },
);
const HeaderContent = loadable(
  () =>
    import(
      /* webpackMode: "lazy" */
      /* webpackFetchPriority: "low" */
      /* webpackChunkName: "component-HeaderContent" */
      './HeaderContent'
    ),
  { ssr: false },
);

const travelerHeaderHeightSP = 59;

type Props = {
  /** Flag to define whether we has connected to the internet or not */
  isOnline: boolean;
};

function Header(props: Props) {
  const dispatch = useDispatch();
  const http = useApiClient();
  const deviceType = useDeviceType();
  const isSP = deviceType === MOBILE_SCREEN;

  const isMaintenanceMode = useSelector(getIsMaintenanceMode);
  const { isMember: isLogin, item: member } = useSelector(getMember);
  const location = useSelector(getLocation);
  const noOfNotification = useSelector(getUnReadCount);
  const market = useSelector(getMarket);
  const markets = useSelector(getMarkets);
  const preferredMarket = useSelector(getPreferredMarket);
  const currency = useSelector(getCurrency);
  const supportedCurrency = useSelector(getCurrencyListOptions);
  const language = useSelector(getLanguage);
  const supportedLanguages = useSelector(getSupportedLanguages);
  const bannerHeight = useSelector(getHeight);
  const isSafari = useSelector(getIsSafari);

  const getTranslation = useGetTranslation();

  const marketCode = market?.marketCode;

  const [pendingCurrencyValue, setPendingCurrencyValue] = useState<string>(currency);
  const [pendingLanguageValue, setPendingLanguageValue] = useState<string>(language);
  const [pendingMarketValue, setPendingMarketValue] = useState<string>(marketCode);
  const [pendingMarketItem, setPendingMarketItem] = useState<MarketItem | undefined>(undefined);
  const [shouldShowSmartBanner, setShouldShowSmartBanner] = useState<boolean>(false);

  const { isOpen: isMenuOpen, onToggle: onToggleMenu, onClose: onCloseMenu } = useDialogHandler(
    false,
  );

  const sideMenuRef = useRef<HTMLDivElement | null>(null);
  const previousFocusRef = useRef<Element | null>(null);
  const nodeRef = useRef<null>(null);
  const isDisplayHeroImage =
    paths.top.alternatePaths.some(path => path === location.pathname) ||
    location.pathname.includes(baseUrl.area);

  const { isCustomFixedStyle, isCustomFixedBarHidden } = useCustomFixedStyling();
  const isTransparentStyle = useTransparentStyling(props.isOnline, isDisplayHeroImage, isMenuOpen);
  const sideMenuLabel = useTranslation({ id: 'GlobNav_Header.Navigation_Menu.Alt' });
  const iconColor = isTransparentStyle && !isMaintenanceMode ? 'white' : undefined;
  const isBookingStepPages =
    location.pathname.includes(baseUrl.booking) &&
    !location.pathname.includes(paths.reservationForm.subPath) &&
    !location.pathname.includes(paths.legacyReservationForm.subPath);
  const isProviderInformationPage = location.pathname.includes(baseUrl.providerInfo);
  const isLoginOrGuestPage = location.pathname.includes(paths.loginOrGuest.path);

  const {
    isOpen: isLanguageDialogOpen,
    onOpen: onOpenLanguageDialog,
    onClose: onCloseLanguageDialog,
  } = useDialogHandler(false);
  const {
    isOpen: isCurrencyDialogOpen,
    onOpen: onOpenCurrencyDialog,
    onClose: onCloseCurrencyDialog,
  } = useDialogHandler(false);
  const {
    isOpen: isMarketDialogOpen,
    onOpen: onOpenMarketDialog,
    onClose: onCloseMarketDialog,
  } = useDialogHandler(false);
  const {
    isOpen: isConfirmMarketDialogOpen,
    onOpen: onOpenConfirmMarketDialog,
    onClose: onCloseConfirmMarketDialog,
  } = useDialogHandler(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hasSmartBanner = !sessionGetItem('shouldHideSmartBanner');
      setShouldShowSmartBanner(hasSmartBanner);
    }
  }, []);

  const languageOptions = useMemo(
    () =>
      supportedLanguages
        ?.map(lang => {
          const translatedText = getTranslation({
            id: `Traveler_Language.${lang.code?.toLowerCase()}`,
          });

          return {
            value: lang.code,
            text: '',
            description: [translatedText],
          };
        })
        .sort((a, b) => sortOptionsByText(a.description, b.description)),
    [supportedLanguages, getTranslation],
  );

  const activeLanguageOption = languageOptions.find(option => option.value === language);

  const onLanguageChange = useCallback(
    async (item?: Option) => {
      const pendingValue = item && item.value ? item.value : pendingLanguageValue;
      if (pendingValue !== language) {
        if (isLogin) {
          await updateLanguageAndCurrency(http, { ...member.siteSetting, language: pendingValue });
        } else {
          setStrictCookie(LAST_USED_LANGUAGE_KEY, pendingValue);
        }

        const redirect = `${window.location.origin}${getURLPagePathName(
          marketCode,
          pendingValue,
          location.pathname,
        )}${location.search}`;
        window.location.assign(redirect);
      }
    },
    [
      http,
      isLogin,
      language,
      location.pathname,
      location.search,
      marketCode,
      member.siteSetting,
      pendingLanguageValue,
    ],
  );

  const currencyOptions = useMemo(
    () =>
      supportedCurrency
        ?.map(currency => {
          const translatedText = getTranslation({
            id: `Traveler_Currency.${currency.value}`,
          });

          return {
            value: currency.value,
            text: currency.text,
            description: [translatedText],
          };
        })
        .sort((a, b) => sortOptionsByText(a.description, b.description)),
    [supportedCurrency, getTranslation],
  );

  const activeCurrencyOption = currencyOptions?.find(option => option.value === currency);

  const onCurrencyChange = useCallback(
    async (item?: Option) => {
      const pendingValue = item && item.value ? item.value : pendingCurrencyValue;

      dispatch(setCurrency(pendingValue));
      if (isLogin) {
        await updateLanguageAndCurrency(http, { ...member.siteSetting, currency: pendingValue });
        dispatch(fetchMember());
      } else {
        setStrictCookie(LAST_USED_CURRENCY_KEY, pendingValue);
      }
      setPendingCurrencyValue(pendingValue);
      onCloseCurrencyDialog();
    },
    [dispatch, http, isLogin, member.siteSetting, onCloseCurrencyDialog, pendingCurrencyValue],
  );

  const marketOptions = useMemo(
    () =>
      markets
        ?.map(market => {
          const translatedText = getTranslation({
            id: `Traveler_Country_List.${market.marketCode}`,
          });

          return {
            value: market.marketCode,
            text: '',
            description: [translatedText],
            icon: MARKET_FLAGS[market.marketCode],
          };
        })
        .sort((a, b) => sortOptionsByText(a.description, b.description)),
    [markets, getTranslation],
  );

  const handleLogout = useCallback(async () => {
    await dispatch(logout());
  }, [dispatch]);

  const changeMarket = useCallback(
    async (pendingValue: MarketItem, isLogout?: boolean) => {
      if (isLogin && !isLogout) {
        await updateLanguageAndCurrency(http, {
          ...member.siteSetting,
          market: pendingValue.marketCode,
        });
      } else {
        setStrictCookie(LAST_USED_MARKET_KEY, pendingValue.marketCode);
      }

      const redirect = `${window.location.origin}${getURLPagePathName(
        pendingValue.marketCode,
        language,
        location.pathname,
      )}${location.search}`;
      window.location.assign(redirect);
    },
    [http, isLogin, language, location.pathname, location.search, member.siteSetting],
  );

  const onMarketChange = useCallback(
    async (item?: Option) => {
      const pendingValue: MarketItem | undefined = markets.find(market => {
        if (item && item.value) {
          return market.marketCode === item.value;
        } else {
          return market.marketCode === pendingMarketValue;
        }
      });

      if (pendingValue?.marketCode !== market.marketCode) {
        if (
          pendingValue &&
          isLogin &&
          warnOnMarketChangeTo.indexOf(pendingValue.marketCode) !== -1
        ) {
          setPendingMarketItem(pendingValue);
          onOpenConfirmMarketDialog();
        } else if (pendingValue) {
          changeMarket(pendingValue);
        }
      }
    },
    [
      changeMarket,
      isLogin,
      market.marketCode,
      markets,
      onOpenConfirmMarketDialog,
      pendingMarketValue,
    ],
  );

  const onConfirmMarketDialog = useCallback(async () => {
    await handleLogout();
    pendingMarketItem && changeMarket(pendingMarketItem, true);
    setPendingMarketItem(undefined);
  }, [changeMarket, handleLogout, pendingMarketItem]);

  const activeMarketOption = marketOptions?.find(option => option.value === marketCode);

  const userName = useMemo(
    () =>
      member.firstName && member.lastName ? (
        <L10nName
          firstName={member.firstName}
          lastName={member.lastName}
          market={marketCode}
        ></L10nName>
      ) : (
        ''
      ),
    [member.firstName, member.lastName, marketCode],
  );
  const points = member.points?.find(point => point.currency === currency)?.totalPoints ?? null;
  const point =
    market?.rakutenPointActive && points !== null ? <L10nNumber value={Number(points)} /> : null;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        onCloseMenu();
      }
      if (event.key === 'Tab') {
        const tabbableElements = sideMenuRef.current?.querySelectorAll(TABBABLE_ELEMENTS);
        const firstFocusableElement = tabbableElements?.[0] as HTMLElement;
        const lastFocusableElement = tabbableElements?.[
          tabbableElements?.length - 1
        ] as HTMLElement;
        if (event.shiftKey && document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement?.focus();
        } else if (!event.shiftKey && document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement?.focus();
        }
      }
    },
    [onCloseMenu],
  );

  const onMenuEntered = useCallback(() => {
    previousFocusRef.current = document.activeElement;
    const tabbableElement = sideMenuRef.current?.querySelector(
      TABBABLE_ELEMENTS.replace(
        'button:not([disabled])',
        `button:not([disabled], .${CSS.escape(styles.closeButton)})`,
      ),
    ) as HTMLElement;
    tabbableElement?.focus();
  }, []);

  useEffect(() => {
    if (!isMenuOpen && previousFocusRef.current instanceof HTMLElement) {
      previousFocusRef.current.focus();
    }
  }, [isMenuOpen]);

  return (
    <>
      <header
        data-testid="header-wrapper"
        className={cx(
          isProviderInformationPage ? styles.notFixedPc : styles.wrapper,
          isCustomFixedStyle && styles.fixed,
          isCustomFixedBarHidden && styles.fixedHidden,
          isMaintenanceMode && styles.disable,
          isTransparentStyle && isSP && styles.absolute,
        )}
        style={
          isCustomFixedStyle && isCustomFixedBarHidden
            ? { transform: `translate(0, -${bannerHeight + travelerHeaderHeightSP}px)` }
            : undefined
        }
      >
        {isSP && !isSafari && shouldShowSmartBanner && (
          <SmartBanner setShouldShowSmartBanner={setShouldShowSmartBanner} />
        )}
        {!isMaintenanceMode && <WideBanner className={styles.wideBanner} />}
        <SwitchTransition mode="out-in">
          <CSSTransition
            nodeRef={nodeRef}
            key={(!isSP || !isDisplayHeroImage) && isTransparentStyle ? 'transparent' : 'default'} // to update animation when style has changed
            timeout={300}
            classNames={{
              enter: styles.headerAnimateEnter,
              enterActive: styles.headerAnimateEnterActive,
              exit: styles.headerAnimateExit,
              exitActive: styles.headerAnimateExitActive,
            }}
          >
            <div
              ref={nodeRef}
              className={cx(
                styles.container,
                isTransparentStyle && !isMaintenanceMode && styles.transparent,
              )}
            >
              <div
                className={styles.menuBar}
                data-testid={`header-type-${isLogin ? 'member' : 'guest'}`}
              >
                <Link
                  data-testid="header-travel-logo"
                  className={styles.logo}
                  to={paths.top.pathResolver()}
                >
                  <TravelLogo color={iconColor} />
                  <span className={styles.accessible}>navigate to top page</span>
                </Link>
                <BetaButton />
                {!isBookingStepPages && !isLoginOrGuestPage && !isMaintenanceMode && (
                  <div className={styles.actionWrapper}>
                    {!isSP && !isMenuOpen && isNotEmptyArray(languageOptions) && language && (
                      <SelectPopup
                        key="language-options"
                        data-testid="header-selectPopup-language"
                        className={styles.optionsBox}
                        customStyle={isTransparentStyle ? 'transparent' : 'default'}
                        list={languageOptions}
                        value={language}
                        text={
                          <IconText
                            className={styles.selectIconTxt}
                            icon={<WorldOutline color={iconColor} size={14} />}
                            text={activeLanguageOption?.description}
                          />
                        }
                        openTo="middle"
                        onClickItem={onLanguageChange}
                      />
                    )}
                    {!isSP && !isMenuOpen && isNotEmptyArray(marketOptions) && marketCode && (
                      <SelectPopup
                        key="market-options"
                        data-testid="header-selectPopup-market"
                        className={styles.optionsBox}
                        customStyle={isTransparentStyle ? 'transparent' : 'default'}
                        list={marketOptions}
                        value={marketCode}
                        text={
                          <IconText
                            className={styles.selectIconTxt}
                            icon={<MapOutline color={iconColor} size={14} />}
                            text={marketCode}
                            data-testid="header-selectPopup-market-iconButton"
                          />
                        }
                        onClickItem={onMarketChange}
                        preferredValue={preferredMarket?.marketCode}
                      />
                    )}
                    {!isSP && !isMenuOpen && isNotEmptyArray(currencyOptions) && currency && (
                      <SelectPopup
                        key="currency-options"
                        data-testid="header-selectPopup-currency"
                        className={styles.optionsBox}
                        customStyle={isTransparentStyle ? 'transparent' : 'default'}
                        list={currencyOptions}
                        value={currency}
                        text={
                          <IconText
                            className={styles.selectIconTxt}
                            icon={<Currency color={iconColor} size={14} />}
                            text={currency}
                          />
                        }
                        onClickItem={onCurrencyChange}
                      />
                    )}
                    {!isSP && !isMenuOpen && isLogin && (
                      <div
                        className={cx(styles.loginInfo, isMenuOpen && styles.invisible)}
                        data-testid="header-div-loginInfo"
                      >
                        <span className={styles.text} data-testid="header-userName">
                          {userName}
                        </span>
                        {point !== null && (
                          <IconText
                            className={styles.icon}
                            icon={<RakutenPoints size={16} />}
                            text={point}
                            spaceBetween={5}
                            data-testid="header-points-iconText"
                          />
                        )}
                      </div>
                    )}

                    <button
                      className={cx(
                        styles.menuButton,
                        Number(noOfNotification) > 0 && !isMenuOpen && styles.active,
                      )}
                      onClick={onToggleMenu}
                      aria-label={sideMenuLabel}
                      data-testid="header-menuButton-button"
                    >
                      {isMenuOpen ? <Close color={iconColor} /> : <Menu color={iconColor} />}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </header>
      {isMenuOpen && (
        <button
          className={cx(
            styles.menuOverlay,
            (isMarketDialogOpen || isLanguageDialogOpen || isCurrencyDialogOpen) &&
              styles.dialogOverlay,
          )}
          onClick={onToggleMenu}
        />
      )}
      <CSSTransition
        nodeRef={sideMenuRef}
        in={isMenuOpen}
        timeout={300}
        unmountOnExit
        onEntered={onMenuEntered}
        classNames={{
          enter: styles.menuDialogAnimateEnter,
          enterActive: styles.menuDialogAnimateEnterActive,
          exit: styles.menuDialogAnimateExit,
          exitActive: styles.menuDialogAnimateExitActive,
        }}
      >
        <div
          role="menu"
          tabIndex={0}
          ref={sideMenuRef}
          onKeyDown={onKeyDown}
          className={styles.menuDialog}
        >
          {!isSP && (
            <button
              className={cx(styles.menuButton, styles.closeButton)}
              onClick={onToggleMenu}
              aria-label="Close menu"
              data-testid="header-close-button"
            >
              <Close size={24} />
            </button>
          )}
          <HeaderContent
            onClose={onToggleMenu}
            noOfNotification={noOfNotification}
            onOpenLanguageDialog={onOpenLanguageDialog}
            onOpenCurrencyDialog={onOpenCurrencyDialog}
            onOpenMarketDialog={onOpenMarketDialog}
            shouldShowSmartBanner={shouldShowSmartBanner}
          />
        </div>
      </CSSTransition>

      {isLanguageDialogOpen && (
        <HeaderDialog
          title={<Translate id="GlobNav_Header.Navigation_Menu.Language" />}
          titleIcon={<WorldOutline size={22} />}
          value={language}
          itemList={languageOptions}
          activeOption={activeLanguageOption}
          onSubmit={language !== pendingLanguageValue ? onLanguageChange : onCloseLanguageDialog}
          isOpen={isLanguageDialogOpen}
          onClose={onCloseLanguageDialog}
          pendingValue={pendingLanguageValue}
          setPendingValue={setPendingLanguageValue}
          hasAnimation={true}
          // TODO: Add title label https://jira.rakuten-it.com/jira/browse/TLM-378
          // optionListTitle={<Translate id="" />}
        />
      )}
      {isCurrencyDialogOpen && (
        <HeaderDialog
          title={<Translate id="GlobNav_Header.Navigation_Menu.Currency" />}
          titleIcon={<Currency size={22} />}
          value={currency}
          itemList={currencyOptions}
          activeOption={activeCurrencyOption}
          onSubmit={() => {
            onCurrencyChange();
            onToggleMenu();
          }}
          isOpen={isCurrencyDialogOpen}
          onClose={onCloseCurrencyDialog}
          pendingValue={pendingCurrencyValue}
          setPendingValue={setPendingCurrencyValue}
          hasAnimation={true}
          // TODO: Add title label https://jira.rakuten-it.com/jira/browse/TLM-378
          // optionListTitle={<Translate id="" />}
        />
      )}
      {isMarketDialogOpen && (
        <HeaderDialog
          title={<Translate id="GlobNav_Header.Navigation_Menu.Region" />}
          titleIcon={<MapOutline size={22} />}
          value={marketCode}
          activeOption={activeMarketOption}
          itemList={marketOptions}
          onSubmit={marketCode !== pendingMarketValue ? onMarketChange : onCloseMarketDialog}
          isOpen={isMarketDialogOpen}
          onClose={onCloseMarketDialog}
          pendingValue={pendingMarketValue}
          setPendingValue={setPendingMarketValue}
          preferredValue={preferredMarket?.marketCode}
          optionListTitle={<Translate id="GlobNav_Header.Navigation_Menu.Region.All" />}
          hasAnimation={true}
        />
      )}
      {isConfirmMarketDialogOpen && (
        <ConfirmDialog
          isOpen={isConfirmMarketDialogOpen}
          onClose={onCloseConfirmMarketDialog}
          onConfirm={onConfirmMarketDialog}
          dialogContent={<Translate id="GlobNav_Header.Navigation_Menu.Logout_Region_Change" />}
          dialogHeader={
            <Translate
              id="GlobNav_Header.Navigation_Menu.Log"
              condition={() => 'status == logged_in'}
            />
          }
          confirmLabel={
            <Translate
              id="GlobNav_Header.Navigation_Menu.Log"
              condition={() => 'status == logged_in'}
            />
          }
          dismissLabel={<Translate id="Traveler_Common.Common.Cancel" />}
        />
      )}
    </>
  );
}

export default Header;
