import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DropdownArrow } from '@travel/icons/ui';
import { CustomStyle, Option } from '@travel/traveler-core/types/header';
import { IconText, Popup } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './selectPopup.module.scss';

type SelectPopupProps = {
  /** String to define custom wrapper className */
  className?: string;
  /** Array of Option to define possible options of the list */
  list: Array<Option>;
  /** The current value shown in the UI */
  text: React.ReactNode;
  /** The current value in string */
  value: string;
  /** The preferred value value in string */
  preferredValue?: string;
  /** String to define <Header /> style */
  customStyle?: CustomStyle;
  /** Callback to be called when an option in the list was clicked */
  onClickItem?: (item: Option) => void;
  /** the direction when most of the menu will land DEFAULT = 'left'*/
  openTo?: 'left' | 'middle';
};

type ItemIndex = number | 'preferred';

const PREFERRED = 'preferred';

function SelectPopup(props: SelectPopupProps) {
  const {
    className,
    list,
    text,
    customStyle,
    onClickItem,
    value,
    preferredValue,
    openTo,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [currentFocus, setCurrentFocus] = useState<ItemIndex>(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const listItemRefs = React.useRef<(HTMLLIElement | null)[]>([]);
  const preferredItemRef = React.useRef<HTMLLIElement | null>(null);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const onBlur = useCallback(
    (e: React.FocusEvent) => {
      if (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node)) {
        onClose();
      }
    },
    [onClose],
  );
  const onToggle = useCallback(() => (isOpen ? onClose() : onOpen()), [onOpen, onClose, isOpen]);
  useEffect(() => {
    if (isOpen) {
      setCurrentFocus(preferredItemRef.current ? PREFERRED : 0);
      preferredItemRef.current
        ? preferredItemRef.current?.focus()
        : listItemRefs.current[0]?.focus();
    }
  }, [isOpen]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (currentFocus === PREFERRED) {
          const nextState = listItemRefs.current.length - 1;
          listItemRefs.current[nextState]?.focus();
        } else {
          let nextState: ItemIndex =
            currentFocus > 0
              ? currentFocus - 1
              : preferredItemRef.current
              ? PREFERRED
              : listItemRefs.current.length - 1;
          nextState === PREFERRED
            ? preferredItemRef.current?.focus()
            : listItemRefs.current[nextState]?.focus();
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (currentFocus === PREFERRED) {
          let nextState = 0;
          listItemRefs.current[nextState]?.focus();
        } else {
          let nextState: ItemIndex =
            currentFocus < listItemRefs.current.length - 1
              ? currentFocus + 1
              : preferredItemRef.current
              ? PREFERRED
              : 0;
          nextState === PREFERRED
            ? preferredItemRef.current?.focus()
            : listItemRefs.current[nextState]?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        wrapperRef.current?.focus();
        onClose();
      } else if (e.key === 'Enter' || e.key === ' ' /** Space */) {
        e.preventDefault();
        onOpen();
      }
    },
    [onClose, onOpen, currentFocus],
  );

  const { preferredItem, items } = React.useMemo(
    () =>
      list?.reduce(
        (
          prev: { preferredItem: React.ReactNode; items: Array<React.ReactNode> },
          item,
          currentIndex,
        ) => {
          const isActive = value === item.value;
          const isPreferred = item.value === preferredValue;

          const handleOnClick = () => {
            onClickItem?.(item);
            onClose();
          };
          const handleKeyDownItem = (e: React.KeyboardEvent) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleOnClick();
            }
          };

          const handleFocus = () => {
            setCurrentFocus(isPreferred ? PREFERRED : currentIndex);
          };

          const itemElement = (
            <li
              key={`${item.text}${item.description}`}
              data-testid={`selectPopup-option-${item.value}`}
              className={cx(styles.optionItem, isActive && styles.active)}
              onClick={handleOnClick}
              onKeyDown={handleKeyDownItem}
              onFocus={handleFocus}
              tabIndex={-1}
              role="menuitem"
              ref={ref => {
                if (isPreferred) {
                  preferredItemRef.current = ref;
                } else {
                  listItemRefs.current[currentIndex] = ref;
                }
              }}
            >
              <span className={styles.optionTitle}>{item.text}</span>
              <span>{item.description}</span>
            </li>
          );
          if (isPreferred) {
            return { preferredItem: itemElement, items: prev.items };
          } else {
            return { preferredItem: prev.preferredItem, items: [...prev.items, itemElement] };
          }
        },
        { preferredItem: null, items: [] },
      ),
    [list, onClickItem, onClose, preferredValue, value],
  );

  return (
    <div
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      ref={wrapperRef}
      className={cx(className, styles.selectPopup)}
      role="menuitem"
      tabIndex={0}
      {...rest}
    >
      <IconText
        text={text}
        icon={
          <DropdownArrow
            className={isOpen ? styles.dropDownOpen : styles.dropDownClose}
            color={customStyle === 'transparent' ? 'white' : 'default'}
          />
        }
        iconPosition="right"
        onClick={onToggle}
        className={styles.label}
      />
      <Popup
        anchorEl={wrapperRef.current}
        isOpen={isOpen}
        isShowTriangle={true}
        onClose={onClose}
        className={cx(styles.popup, openTo && styles[openTo])}
        openTo={openTo}
      >
        <ul className={styles.optionList}>
          {preferredItem}
          {items}
        </ul>
      </Popup>
    </div>
  );
}

export default SelectPopup;
