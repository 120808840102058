import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import { cx } from '@travel/utils';

import { setWideBannerHeight } from 'store/banner/actions';
import { getBanner, getIsFetching, getShouldDisplayWideBanner } from 'store/banner/selectors';

import useOnResizeDebounce from 'hooks/useOnResizeDebounce';

import styles from './wideBanner.module.scss';

type Props = {
  className?: string;
};

function WideBanner(props: Props) {
  const wrapperRef = useRef<HTMLAnchorElement | null>(null);

  const isMobile = useDeviceType() === MOBILE_SCREEN;
  const dispatch = useDispatch();
  const { item: banner } = useSelector(getBanner);
  const shouldDisplayBanner = useSelector(getShouldDisplayWideBanner);
  const isFetching = useSelector(getIsFetching);

  const onImageLoaded = useCallback(() => {
    if (!isFetching && shouldDisplayBanner && wrapperRef.current) {
      dispatch(setWideBannerHeight(wrapperRef.current.getBoundingClientRect().height || 0));
    }
  }, [dispatch, isFetching, shouldDisplayBanner]);

  useEffect(() => {
    // to support SSR landing
    onImageLoaded();
  }, [banner, dispatch, isFetching, onImageLoaded, shouldDisplayBanner]);

  useOnResizeDebounce(onImageLoaded);

  if (!shouldDisplayBanner || isFetching || (!banner.img_src && !banner.desktop_img_src)) {
    return null;
  }

  return (
    <a
      href={banner.href}
      className={cx(styles.banner, props.className)}
      data-testid="wideBanner-wrapper"
      ref={wrapperRef}
    >
      <img
        // @ts-ignore
        fetchpriority="high"
        src={isMobile ? banner.img_src : banner.desktop_img_src}
        className={styles.media}
        alt={banner.title || ''}
        onLoad={onImageLoaded}
        sizes="100vw"
        width="100%"
      />
    </a>
  );
}

export default WideBanner;
