import { useCallback, useEffect, useState } from 'react';

import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import { HERO_IMAGE_HEIGHT } from 'components/HeroImage/HeroImageSlider';

function useTransparentStyling(
  isOnline: boolean = true,
  isDisplayHeroImage: boolean = false,
  isMenuOpen: boolean = false,
) {
  const isMobile = useDeviceType() === MOBILE_SCREEN;

  const [isTransparentStyle, setIsTransparentStyle] = useState(isOnline && isDisplayHeroImage);

  const updateHeaderStyle = useCallback(() => {
    if (!isDisplayHeroImage || (isDisplayHeroImage && window.pageYOffset > HERO_IMAGE_HEIGHT)) {
      if (isTransparentStyle) {
        setIsTransparentStyle(false);
      }
    } else if (isDisplayHeroImage && window.pageYOffset <= HERO_IMAGE_HEIGHT) {
      if (!isTransparentStyle) {
        setIsTransparentStyle(true);
      }
    }
  }, [isDisplayHeroImage, isTransparentStyle]);

  useEffect(() => {
    if (isDisplayHeroImage) {
      if (isMobile) {
        setIsTransparentStyle(!isMenuOpen);
      } else {
        updateHeaderStyle();
        document.addEventListener('scroll', updateHeaderStyle);
      }
    } else {
      setIsTransparentStyle(false);
    }

    return () => {
      if (!isMobile && isDisplayHeroImage) {
        document.removeEventListener('scroll', updateHeaderStyle);
      }
    };
  }, [isDisplayHeroImage, isMenuOpen, isMobile, updateHeaderStyle]);

  return isTransparentStyle;
}

export default useTransparentStyling;
