import React, { useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Translate from '@travel/traveler-core/components/Translate';
import { Popup } from '@travel/ui';

import {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
  useDeviceType,
  useDialogHandler,
  useTranslation,
} from '../../hooks';

import styles from './betaButton.module.scss';

function BetaButton() {
  const deviceType = useDeviceType();
  const nodeRef = useRef<null>(null);
  const isSP = deviceType === MOBILE_SCREEN;
  const isPC = deviceType === DESKTOP_SCREEN;

  const {
    isOpen: isBetaTextOpen,
    onOpen: onOpenBetaText,
    onClose: onCloseBetaText,
    onToggle: onToggleBetaText,
  } = useDialogHandler(false);

  const betaButtonRef = useRef<HTMLButtonElement | null>(null);
  const popupDisappearTimerRef = useRef<NodeJS.Timeout | number>(0);

  const onMouseLeaveTimer = useCallback(() => {
    popupDisappearTimerRef.current = setTimeout(() => onCloseBetaText(), 100);
  }, [onCloseBetaText]);

  const onMouseEnter = useCallback(() => {
    clearTimeout(popupDisappearTimerRef.current as number);
    onOpenBetaText();
  }, [onOpenBetaText]);

  const onBlur = useCallback(() => {
    onCloseBetaText();
  }, [onCloseBetaText]);

  const betaLink = useTranslation({
    id: 'Traveler_Common.Standard_URL.Tooltip.Feedback_Here',
  });

  return (
    <div className={styles.betaWrapper} onFocus={onMouseEnter} onBlur={onBlur}>
      <button
        className={styles.beta}
        ref={betaButtonRef}
        onClick={!isPC ? onToggleBetaText : undefined}
        onMouseEnter={isPC ? onMouseEnter : undefined}
        onMouseLeave={isPC ? onMouseLeaveTimer : undefined}
        data-testid={'header-beta-button'}
      >
        <Translate id="Traveler_Common.Common.Title.Beta" />
      </button>
      <CSSTransition
        in={isBetaTextOpen}
        timeout={300}
        unmountOnExit
        nodeRef={nodeRef}
        classNames={{
          enter: styles.popupDialogEnter,
          enterActive: styles.popupDialogEnterActive,
          exit: styles.popupDialogExit,
          exitActive: styles.popupDialogExitActive,
        }}
      >
        <div ref={nodeRef} className={styles.popupContainer}>
          <Popup
            anchorEl={betaButtonRef.current}
            isOpen={true}
            isShowTriangle={true}
            onClose={onCloseBetaText}
            onMouseEnter={isPC ? onMouseEnter : undefined}
            onMouseLeave={isPC ? onMouseLeaveTimer : undefined}
            openTo={isSP ? 'extremeLeft' : 'right'}
            className={styles.popup}
            data-testid={'header-beta-popup'}
          >
            <span className={styles.popupText} role="tooltip">
              <Translate
                id="Traveler_Common.Common.Title.Beta_Tooltip"
                data={{
                  feedback_link_here: (
                    <a href={betaLink} target="_blank" rel="noopener noreferrer">
                      <Translate id="Traveler_Common.Standard_Link.Tooltip.Feedback_Here" />
                    </a>
                  ),
                }}
              />
            </span>
          </Popup>
        </div>
      </CSSTransition>
    </div>
  );
}

export default BetaButton;
