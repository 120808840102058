import React from 'react';
import { useSelector } from 'react-redux';

import { MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH } from '@travel/traveler-core/constants/styles';
import useDeviceType, { DESKTOP_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import { ImagePlaceHolder } from '@travel/ui';
import { isEmptyArray } from '@travel/utils';

import Media from 'components/Media';
import Swiper from 'components/Swiper';

import { getHeight } from 'store/banner/selectors';

import styles from './heroImageSlider.module.scss';

export const HERO_IMAGE_HEIGHT = 465;

type HeroImage = {
  /** String of image URL */
  url?: string;
  /** String of image title */
  alt?: string;
};

type Props = {
  /** Array of URL string */
  heroImages?: Array<HeroImage>;
};

function HeroImageSlider(props: Props) {
  const isPC = useDeviceType() === DESKTOP_SCREEN;
  const bannerHeight = useSelector(getHeight);

  if (isEmptyArray(props.heroImages)) {
    return <ImagePlaceHolder className={styles.imageSlider} />;
  }

  return (
    <div
      id="hero-image-slider"
      className={styles.imageSlider}
      data-testid="heroImageSlider-swiper-wrapper"
      style={isPC ? { top: `${bannerHeight}px` } : undefined}
    >
      <Swiper paginationType="swipe">
        {props.heroImages?.map((heroImage, index) => (
          <Media
            key={heroImage.url}
            srcsetStartWidth={isPC ? TABLET_MIN_WIDTH : MOBILE_MIN_WIDTH}
            wrapperClassName={styles.mediaWrapper}
            media={{ url: heroImage.url, alt: heroImage.alt }}
            sizes="100vw"
            {...(index === 0 ? { isDisableLazyload: true, fetchPriority: 'high' } : {})}
          />
        ))}
      </Swiper>
    </div>
  );
}

export default HeroImageSlider;
