import { useCallback, useState } from 'react';

const useDialogHandler = (defaultState: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultState);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

  return { isOpen, onOpen, onClose, onToggle };
};

export default useDialogHandler;
